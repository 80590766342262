<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col sm="8">
            <h4 class="card-title m-0">
              {{ title }}
            </h4>
          </b-col>

          <!-- Add -->
          <b-col
            v-if="$ability.can('C', 'Modules & Menus')"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'system-settings-menus-create' }"
              >
                <span class="text-nowrap">Add Menu</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refMenuListTable"
        class="position-relative"
        :items="menus"
        responsive
        :fields="tableColumns"
        primary-key="menuId"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$ability.can('E', 'Modules & Menus')"
              :to="{ name: 'system-settings-menus-edit', params: { id: data.item.menuId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$ability.can('D', 'Modules & Menus') && data.item.status !== 'D'"
              @click="deleteMenu({ operation: 'D', menuId: data.item.menuId })"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- Column: Show In Sidebar -->
        <template #cell(showInSidebar)="data">
          <b-avatar
            :variant="data.item.showInSidebar ? 'light-success' : 'light-danger'"
            size="16"
          >
            <feather-icon
              :icon="data.item.showInSidebar ? 'CheckCircleIcon' : 'XCircleIcon'"
              size="16"
            />
          </b-avatar>

        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15)'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">
          <sub-menus-list
            :sub-menus="data.item.submenus"
            @refetch-data="refetchData"
          />
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BBadge, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import useModulesList from '../../modules/modules-list/useModulesList'
import useMenusList from './useMenusList'
import SubMenusList from './sub-menus-list/SubMenusList.vue'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    SubMenusList,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    menus: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    // const {
    //   resolveStatus,
    //   resolveStatusVariant,
    // } = useModulesList()

    const {
      tableColumns,
      resolveIsApp,
    } = useMenusList()

    const refetchData = () => {
      emit('refetch-data')
    }

    const deleteMenu = async deleteParams => {
      await store.dispatch('app-menu/procMenu', deleteParams)
      refetchData()
    }

    return {
      tableColumns,

      resolveStatus,
      resolveStatusVariant,
      resolveIsApp,

      deleteMenu,
      refetchData,
    }
  },
}
</script>
