import store from '@/store'
import menuStoreModule from '../menuStoreModule'

export default function useMenusList() {
  const MENU_APP_STORE_MODULE_NAME = 'app-menu'

  // Register module
  if (!store.hasModule(MENU_APP_STORE_MODULE_NAME)) store.registerModule(MENU_APP_STORE_MODULE_NAME, menuStoreModule)

  const tableColumns = [
    { key: 'actions', label: 'Actions' },
    { key: 'description', label: 'Description' },
    { key: 'resource' },
    // { key: 'is_app', label: 'Is Menu Present In Application?', class: 'text-center' },
    { key: 'route', label: 'Route' },
    { key: 'showInSidebar', class: 'text-center' },
    { key: 'status', label: 'Status' },

    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]

  const resolveIsApp = isApp => (isApp == 1 ? 'Yes' : 'No')

  return {
    tableColumns,

    resolveIsApp,
  }
}
